@import "src/styles/vars.scss";

.Footer {
  background: $bg-gray;
  padding: 60px 0;

  .title {
    font-size: 26px;
    color: black;
    font-weight: bold;
    margin-bottom: 45px
  }
  .contact {
    font-size: 22px;
    color: black;
    font-weight: lighter;
    margin-bottom: 30px
  }
  .callbackForm {
    :global {
      form {
        display: flex;
        flex-direction: column;

        input,
        textarea {
         margin-bottom: 15px;
        }

        button {
          padding: 15px;
          border: 3px solid $generic-color;
          color: $generic-color;
          background: white;
        }
      }
    }
  }
}