@import "src/styles/vars.scss";

.About {
  background: $bg-gray;

  p {
    white-space: pre-wrap;
    font-size: 14px;
  }

  img {
    width: 100%;
    margin-bottom: 15px;
    transition: all 0.3s ease-in-out;

    &:hover {
      box-shadow: 0 0 10px #010101;
    }
  }
}