@keyframes heart {
  0% {transform: scale(1,1)}
  20% {transform: scale(1.1,1.1)}
  40% {transform: scale(0.9,0.9)}
  60% {transform: scale(1,1)}
  100% {transform: scale(1,1)}
}

.GlobalPageLoader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  display: flex;
  z-index: 12;

  .loader {
    margin: auto;
    height: 70px;
    width: 70px;
    background-image: url(../../../img/heart.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    animation-duration: 3s;
    animation-name: heart;
    animation-iteration-count: infinite;
  }
}