@import "src/styles/vars.scss";

.Article {
  padding: 60px 15px;


  .title {
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .back {
    font-size: 16px;
    padding-left: 45px;
    position: relative;
    margin-bottom: 30px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 30px;
      background-image: url(../../../img/multimedia-option.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  p {
    white-space: pre-wrap;
    line-height: 1.8;
    font-size: 16px;
  }

  form {
    display: flex;
    flex-direction: column;

    input,
    textarea {
      margin-bottom: 15px;
    }

    button {
      padding: 15px;
      border: 3px solid $generic-color;
      color: $generic-color;
      background: white;
    }
  }
}