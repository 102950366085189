.socials {
  .social {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 30px;
    width: 30px;
    margin-bottom: 15px;
    display: block;

    &.facebook {
      background-image: url("./img/facebook.svg");
    }
    &.instagram {
      background-image: url("./img/instagram-sketched.svg");
    }
  }
}