@import 'src/styles/vars.scss';

html, body, #root {
    min-height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
}

* {
    outline: none;
    font-family: Arial;
}

h1, h2, h3, h4, h5, h6, a, p, span, ul, li {
    display: inline-block;
    font-size: 13px;
    color: #010101;
    text-decoration: none;
    list-style: none;
}

input {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #ccc;
    padding: 10px 20px;
}
textarea {
    background: transparent;
    border: 1px solid #ccc;
    padding: 10px 20px;
    resize: none;
}

.loader {
    .line-scale {
        margin-top: 8px;

        & > * {
            background: $generic-color;
        }
    }
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -15px; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 15px; /* gutter size */
    background-clip: padding-box;
}

.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 15px;
}