@import "src/styles/vars.scss";

.Info {
  padding: 30px;

  &.danger {
    background: $danger;
  }
  &.success {
    background: $success;
  }
}