@import 'src/styles/vars.scss';

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.HeaderForm {
  padding: 60px 0;
  background: $generic-color;

  .title {
    font-size: 26px;
    color: white;
    text-align: center;
    margin-bottom: 45px;
  }

  .form {
    margin: 0 -7.5px;

    :global {
      form {
        display: flex;
        justify-content: center;

        & > * {
          width: 100%;
        }

        @include media-breakpoint-down(sm) {
          flex-direction: column;

          input {
            margin-bottom: 15px !important;
          }
          input,
          button {
            width: auto;
          }

          button {
            height: 60px;
          }
        }

        input,
        button {
          margin: 0 7.5px;
          background: white;
          border: 1px solid #ccc
        }
      }
    }
  }
}