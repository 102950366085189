@import "src/styles/vars.scss";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.Header {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  padding: 15px 0;
  background: $generic-color;
  display: flex;
  justify-content: center;
  height: 60px;
  z-index: 10;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  a {
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin: 0 15px;
    margin-left: 0;
  }

  :global {
    .container {
      padding: 0
    }
  }
}

.mediaHeader {
  display: none;
  padding: 0 15px;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  height: 60px;
  background-color: $generic-color;
  z-index: 10;

  @include media-breakpoint-down(sm) {
    display: flex;

    .backArrow {
      margin: auto auto auto 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url(../../../img/multimedia-option.svg);
      height: 30px;
      width: 30px;
    }
  }
}