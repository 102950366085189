@import "src/styles/vars.scss";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.FAQ {
  background: white;
  width: 100%;
}

.Question {
  color: #010101;
  font-size: 23px;
  margin-bottom: 30px;
  padding-top: 30px;
  position: relative;
  cursor: pointer;

  @include media-breakpoint-down(sm) {
    font-size: 17px;
  }

  &.active {
    color: $generic-color;

    &:after {
      background: $generic-color;
      width: 250px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    top:0;
    left: 0;
    height: 5px;
    width: 100px;
    background: #010101;
  }
}

.Answer {
  font-size: 16px;
  line-height: 1.7;

  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
}