@import "src/styles/vars.scss";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

@mixin withImage() {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.ResultsWrapper {
  background: $bg-gray;

  .Results {
    height: 600px;
    width: 600px;
    position: relative;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
      width: 300px;
      height: 300px;
    }

    .before {
      @include withImage;
    }
    .after {
      @include withImage;
      width: 600px;
    }

    .navigation {
      display: flex;
      justify-content: space-between;

      .prev,
      .next {
        height: 60px;
        width: 60px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
      .prev {
        background-image: url(./img/arrows.svg);
      }
      .next {
        background-image: url(./img/arrow.svg);
      }
    }
  }
}
