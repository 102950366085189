.Button {
  position: relative;

  &.isLoading {
    pointer-events: none;

    .content {

    }
    .loader {
      visibility: visible;
    }
  }
  .content {

  }
  .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #fff;
    visibility: hidden;
  }
}